bcl.c.distributor.buttons = {
  init: function () {
    bcl.c.distributor.buttons.processButtons();
  },

  processButtons: function () {
    for (let i = 0; i < bcl.c.distributor.buttons.props.items.length; i++) {
      bcl.c.distributor.buttons.processButton(bcl.c.distributor.buttons.props.items[i]);
    }
  },

  processButton: function (item) {
    const node = item.parentNode ? bcl.c.distributor.props.$container.parentNode : bcl.c.distributor.props.$container,
      $buttons = node.getElementsByClassName(item.className);

    if (!$buttons) {
      return;
    }

    const event = item.event || "click";

    bcl.u.forEach($buttons, function ($button) {
      $button.addEventListener(event, function () {
        bcl.s.tooltip.closeActive();
        item.callback($button, item);
        bcl.u.triggerEvent(window, "cmp-image-global-redraw");
      });

      if (!item.notAddNode) {
        bcl.c.distributor.buttons.props.$nodes.push($button);
      }
    });
  },

  clickComparator: function ($button, item) {
    bcl.c.distributor.requestAsyncData(bcl.c.distributor.buttons.clickComparatorCallback, $button, item);
    bcl.u.triggerEvent(window, "scroll");
  },

  clickComparatorCallback: function ($button, item) {
    if (bcl.c.distributor.comparator.props.disabled) {
      return;
    }

    if (bcl.c.distributor.comparator.props.tooltipActive) {
      bcl.s.tooltip.closeActive();
    }

    bcl.u.toggleClass(bcl.c.distributor.props.$container, item.classNameContainer);

    if (bcl.c.distributor.props.$container.dataset.comparator) {
      bcl.c.distributor.comparator.pendingOpenComparator("flag");
    }

    bcl.c.distributor.goToNode(bcl.c.distributor.props.$container);
  },

  clickMapOrList: function () {
    const firstTime = Object.assign({}, bcl.c.distributor.props);
    for (let i = 0; i < bcl.c.distributor.buttons.props.$nodes.length; i++) {
      let className = bcl.c.distributor.props.classNameHidden;
      const $node = bcl.c.distributor.buttons.props.$nodes[i],
        mdBlock = "md:block",
        mdHidden = "md:hidden",
        active = "active";

      if (bcl.u.containsClass($node, mdBlock)) {
        className = mdHidden;
        bcl.u.toggleClass($node, mdBlock);
      }

      if (bcl.u.containsClass($node, mdHidden)) {
        className = mdBlock;
        bcl.u.toggleClass($node, mdHidden);
      }
      if (!bcl.c.distributor.map.props?.$container?.closest(".JS-offers-collections")) {
        bcl.u.toggleClass($node, className);
      } else {
        bcl.u.toggleClass($node, active);
      }
    }

    if (bcl.c.distributor.comparator.props.tooltipActive) {
      bcl.s.tooltip.closeActive();
    }

    if ((!firstTime.loadDataAsync && !bcl.c.distributor.props.loaded) || !bcl.c.distributor.map.props.loaded) {
      if (!bcl.c.distributor.map.props.loaded) {
        bcl.c.distributor.map.handleInit(function () {
          bcl.c.distributor.map.init();
          bcl.c.distributor.requestAsyncData(bcl.c.distributor.initMarkerMaps);
        });
      } else {
        bcl.c.distributor.initMarkerMaps();
      }
    }

    //caso menos de 10
    if (!firstTime.loadDataAsync && bcl.c.distributor.props.loaded && !bcl.c.distributor.map.props.loaded) {
      bcl.c.distributor.map.handleInit(function () {
        bcl.c.distributor.map.init();
        bcl.c.distributor.initMarkerMaps();
      });
    }

    bcl.u.toggleClass(bcl.c.distributor.map.props.$container, bcl.c.distributor.props.classNameHidden);
    bcl.u.toggleClass(bcl.c.distributor.results.props.$container, bcl.c.distributor.props.classNameHidden);
    bcl.u.toggleClass(bcl.c.distributor.order.props.$container, bcl.c.distributor.props.classNameHidden);
    bcl.u.toggleClass(bcl.c.distributor.comparator.props.$container, bcl.c.distributor.props.classNameHidden);

    // eslint-disable-next-line no-undef
    const $navigationContainer = bcl.u.closest($node, "." + bcl.c.distributor.comparator.props.navigationClass);
    $navigationContainer && bcl.u.toggleClass($navigationContainer, bcl.c.distributor.comparator.props.modViewMapClass);

    if ((!bcl.u.containsClass(bcl.c.distributor.map.props.$container, bcl.c.distributor.props.classNameHidden) && firstTime.loadDataAsync) || bcl.c.distributor.props.loaded) {
      if (bcl.c.distributor.map.props.loaded) {
        bcl.c.distributor.map.setFitBounds(bcl.c.distributor.map.props);
      }
    }

    bcl.c.distributor.goToNode(bcl.c.distributor.props.$container);
  },

  clickFiltersMobile: function () {
    if (bcl.u.mobile.isMobile()) {
      bcl.c.distributor.requestAsyncData();
      if (bcl.c.distributor.facets?.props?.$containerSidebar) {
        bcl.u.toggleClass(bcl.c.distributor.facets.props.$containerSidebar, bcl.c.distributor.facets.props.classNameOpenMobile);
        bcl.u.toggleClass(document.body, bcl.c.distributor.facets.props.activeHotelFilterClass);
      }
    }
  },

  clickCompare: function () {
    if (bcl.c.distributor.comparator.props.countHotel > 1) {
      bcl.c.distributor.comparator.showOrHideComparator();
    }
  },

  clickCheckboxHiddenEquals: function () {
    bcl.u.toggleClass(bcl.c.distributor.comparator.props.$containerComparated, bcl.c.distributor.comparator.props.classNameActiveHiddenByCheck);
  },

  clickSeeLocationOnMap: function () {
    bcl.c.distributor.goToNode(bcl.c.distributor.comparator.props.$containerMap);
  },
};

bcl.c.distributor.buttons.props = {
  $nodes: [],
  items: [
    {
      className: "comparator-btn-compared",
      callback: bcl.c.distributor.buttons.clickComparator,
      classNameContainer: "active-comparator",
    },
    {
      className: "map-btn",
      callback: bcl.c.distributor.buttons.clickMapOrList,
    },
    {
      className: "list-btn",
      callback: bcl.c.distributor.buttons.clickMapOrList,
    },
    {
      className: "comparing__footer-comparator-btn",
      callback: bcl.c.distributor.buttons.clickCompare,
      notAddNode: true,
    },
    {
      className: "comparing__footer-close-btn",
      callback: bcl.c.distributor.buttons.clickComparator,
      classNameContainer: "active-comparator",
      notAddNode: true,
    },
    {
      className: "return-search-JS",
      callback: bcl.c.distributor.buttons.clickCompare,
      parentNode: true,
      notAddNode: true,
    },
    {
      className: "checkbox-hidden-equals",
      callback: bcl.c.distributor.buttons.clickCheckboxHiddenEquals,
      notAddNode: true,
      parentNode: true,
      event: "change",
    },
    {
      className: "main-nav__hotels-btn",
      callback: bcl.c.distributor.buttons.clickFiltersMobile,
      notAddNode: true,
    },
    {
      className: "sidebar__close-btn",
      callback: bcl.c.distributor.buttons.clickFiltersMobile,
      notAddNode: true,
    },
    {
      className: "apply-filters",
      callback: bcl.c.distributor.buttons.clickFiltersMobile,
      notAddNode: true,
    },
    {
      className: "delete-filters-btn",
      callback: bcl.c.distributor.facets.clickInClearAll,
      notAddNode: true,
    },
    {
      className: "location-map-JS",
      callback: bcl.c.distributor.buttons.clickSeeLocationOnMap,
      notAddNode: true,
      parentNode: true,
    },
  ],
};
