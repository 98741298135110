/* eslint-disable camelcase */
bcl.c.distributor.comparator = {
  props: {
    className: "c-hotels-comparator__comparing",
    classNameItems: "comparing__container-content",
    classNameValueItems: "comparing__header-items",
    classNameItemsSpan: "comparing__container-title",
    classNameItemActive: "active",
    classNameButtonDisabled: "mod--cta-disabled",
    classNameComparated: "c-hotels-comparated",
    classNameActiveHiddenByCheck: "active-hidden-check",
    classNameHiddenByCheck: "hidden-check",
    classNameHiddenBlock: "hidden-children",
    classNameButtonComparator: "comparing__footer-comparator-btn",
    classNameDeleteItem: "delete-item-compare-JS",
    classNameNoDeleteItems: "hidden-delete-items-compare",
    classNameMap: "map-compare-JS",
    nameAttrPathInfo: "data-info-path",
    nameSecondAttrPathInfo: "data-info-path-second",
    nameAttrPathRoom: "data-room-path",
    nameAttrRoom: "data-room",
    cookieComparatorPopover: "barcelo_COMPARATOR_popover",
    navigationClass: "body__main-nav-JS",
    modViewMapClass: "mod--view-map",
    $container: null,
    $valueItems: null,
    $containerItems: null,
    $containerItemsTitle: null,
    $containerComparated: null,
    latDefault: 36.003204,
    lngDefault: -7.976789,
    markers: [],
    markerClustering: undefined,
    countHotel: 0,
    maxHotel: 3,
    data: {},
    update: false,
  },

  init: function () {
    if (!bcl.c.distributor.comparator.checkInit()) {
      return;
    }

    bcl.c.distributor.comparator.props.$containerComparated = bcl.c.distributor.props.$container.parentNode.getElementsByClassName(bcl.c.distributor.comparator.props.classNameComparated)[0];

    bcl.s.sticky.addStickyBottom({
      $node: bcl.c.distributor.comparator.props.$container.children[0],
      inverse: true,
    });

    bcl.c.distributor.comparator.initNodes();
    bcl.c.distributor.comparator.processDeleteItems();

    bcl.c.distributor.comparator.isPendingOpenComparator();

    const $node = document.querySelector("." + bcl.c.distributor.props.className);
    if ($node.dataset.comparator) {
      const $comparatorButton = $node.querySelector("." + bcl.c.distributor.buttons.props.items[0].className);
      bcl.u.triggerEvent($comparatorButton, "click");
    }
  },

  checkInit: function () {
    const $node = bcl.c.distributor.props.$container.getElementsByClassName(bcl.c.distributor.comparator.props.className);

    if ($node && $node[0]) {
      bcl.c.distributor.comparator.props.$container = $node[0];
      return true;
    }

    return false;
  },

  initNodes: function () {
    bcl.c.distributor.comparator.props.$valueItems = bcl.c.distributor.comparator.props.$container.getElementsByClassName(bcl.c.distributor.comparator.props.classNameValueItems)[0];
    bcl.c.distributor.comparator.props.$containerItems = bcl.c.distributor.comparator.props.$container.getElementsByClassName(bcl.c.distributor.comparator.props.classNameItems)[0];
    bcl.c.distributor.comparator.props.$containerItemsTitle = bcl.c.distributor.comparator.props.$container.getElementsByClassName(bcl.c.distributor.comparator.props.classNameItemsSpan)[0];
    bcl.c.distributor.comparator.props.$containerButtonComparator = bcl.c.distributor.comparator.props.$container.getElementsByClassName(bcl.c.distributor.comparator.props.classNameButtonComparator)[0].children[0];
  },

  processDeleteItems: function () {
    const $items = bcl.c.distributor.comparator.props.$containerComparated.getElementsByClassName(bcl.c.distributor.comparator.props.classNameDeleteItem);

    bcl.u.forEach($items, bcl.c.distributor.comparator.processDeleteItem);
  },

  processDeleteItem: function ($item, index) {
    $item.addEventListener("click", function () {
      bcl.u.addClass(bcl.c.distributor.comparator.props.$containerComparated, bcl.c.distributor.comparator.props.classNameNoDeleteItems);

      bcl.u.addClass(bcl.c.distributor.comparator.props.$containerComparated, "data-info-" + (index + 1) + "-hide");

      const $nodes = bcl.c.distributor.comparator.props.$container.getElementsByClassName("c-close-btn");

      if ($nodes) {
        bcl.u.triggerEvent($nodes[index], "click");
      }
    });
  },

  processCard: function ($element) {
    if (!bcl.c.distributor.comparator.props.$container && !bcl.c.distributor.comparator.checkInit()) {
      return;
    }

    $element.children[0].addEventListener("click", function () {
      bcl.c.distributor.comparator.clickInCompare($element, this, $element.children[1]);
    });

    if (bcl.c.distributor.comparator.props.fullComparator) {
      bcl.c.distributor.comparator.clickInCompare($element, $element.children[0], $element.children[1]);
    }
  },

  clickInCompare: function ($element, $compare, $card) {
    bcl.c.distributor.isLoadFullData($element, $card);

    if (bcl.u.containsClass($compare, bcl.c.distributor.comparator.props.classNameItemActive)) {
      bcl.u.removeClass($compare, bcl.c.distributor.comparator.props.classNameItemActive);
      bcl.c.distributor.comparator.props.countHotel--;
      bcl.c.distributor.comparator.updateButton();

      bcl.c.distributor.comparator.removeItem($element, $card);
    } else if (bcl.c.distributor.comparator.props.countHotel < bcl.c.distributor.comparator.props.maxHotel) {
      bcl.u.addClass($compare, bcl.c.distributor.comparator.props.classNameItemActive);
      bcl.c.distributor.comparator.props.countHotel++;
      bcl.c.distributor.comparator.updateButton();

      bcl.c.distributor.comparator.addItem($element, $card);
    }

    if (bcl.c.distributor.comparator.props.$containerButtonComparator && bcl.c.distributor.comparator.props.countHotel > 1) {
      bcl.u.removeClass(bcl.c.distributor.comparator.props.$containerButtonComparator, "mod--cta-disabled");
    } else {
      bcl.u.addClass(bcl.c.distributor.comparator.props.$containerButtonComparator, "mod--cta-disabled");
    }

    bcl.c.distributor.comparator.checkStatusComparator();
  },

  updateButton: function () {
    if (bcl.c.distributor.comparator.props.$containerButtonComparator) {
      if (bcl.c.distributor.comparator.props.countHotel > 1 && bcl.c.distributor.props.pendingFullData === 0) {
        bcl.u.removeClass(bcl.c.distributor.comparator.props.$containerButtonComparator.parentNode, bcl.c.distributor.comparator.props.classNameButtonDisabled);
      } else {
        bcl.u.addClass(bcl.c.distributor.comparator.props.$containerButtonComparator.parentNode, bcl.c.distributor.comparator.props.classNameButtonDisabled);
      }
    }
  },

  addItem: function ($element, $card) {
    const $cloneCard = $card.cloneNode(true),
      nameAttrCard = bcl.c.distributor.results.props.nameAttrCard,
      id = $element.getAttribute(nameAttrCard);

    $cloneCard.setAttribute(nameAttrCard, id);

    bcl.c.distributor.comparator.props.data[id] = $cloneCard;

    if (bcl.c.distributor.comparator.props.$containerItems) {
      bcl.c.distributor.comparator.props.$containerItems.insertBefore($cloneCard, bcl.c.distributor.comparator.props.$containerItems.children[bcl.c.distributor.comparator.props.countHotel - 1]);
      bcl.u.addClass(bcl.c.distributor.comparator.props.$containerItems.children[bcl.c.distributor.comparator.props.countHotel + bcl.c.distributor.comparator.props.countHotel - 1], "hidden");
    }

    bcl.c.distributor.comparator.closeInItemSelected($element, $card, $cloneCard);

    bcl.c.distributor.comparator.props.update = true;

    const $item = bcl.c.distributor.comparator.props.$container.querySelector(".accordion-item");

    if (!bcl.u.containsClass($item, "active")) {
      bcl.c.accordion.processClick($item.querySelector("." + bcl.c.accordion.props.cssSelectorContent));
    }
  },

  closeInItemSelected: function ($element, $card, $cloneCard) {
    const $close = $cloneCard.getElementsByClassName("c-close-btn")[0];
    if (typeof $close !== "undefined") {
      $close.addEventListener("click", function () {
        bcl.c.distributor.comparator.clickInCompare($element, $element.children[0], $card);
      });
    }
  },

  removeItem: function ($element) {
    const nameAttrCard = bcl.c.distributor.results.props.nameAttrCard,
      valueAttr = $element.getAttribute(nameAttrCard),
      $node = bcl.c.distributor.comparator.props.$containerItems.querySelector("[" + nameAttrCard + '="' + valueAttr + '"]');

    if ($node) {
      bcl.c.distributor.comparator.props.$containerItems.removeChild($node);
    }

    delete bcl.c.distributor.comparator.props.data[valueAttr];

    bcl.c.distributor.comparator.props.update = true;

    bcl.u.removeClass(bcl.c.distributor.comparator.props.$containerItems.children[bcl.c.distributor.comparator.props.countHotel + bcl.c.distributor.comparator.props.countHotel], "hidden");
  },

  checkStatusComparator: function () {
    const classNameHidden = bcl.c.distributor.props.classNameHidden;

    if (bcl.c.distributor.comparator.props.countHotel !== 0) {
      bcl.u.removeClass(bcl.c.distributor.comparator.props.$containerItems, classNameHidden);
      bcl.u.addClass(bcl.c.distributor.comparator.props.$containerItemsTitle, classNameHidden);
    } else {
      bcl.u.addClass(bcl.c.distributor.comparator.props.$containerItems, classNameHidden);
      bcl.u.removeClass(bcl.c.distributor.comparator.props.$containerItemsTitle, classNameHidden);
    }

    if (bcl.c.distributor.comparator.props.$valueItems) {
      bcl.c.distributor.comparator.props.$valueItems.innerText = bcl.c.distributor.comparator.props.countHotel;
    }
  },

  showOrHideComparator: function () {
    let $node;

    bcl.u.removeClass(bcl.c.distributor.comparator.props.$containerComparated, bcl.c.distributor.comparator.props.classNameNoDeleteItems);
    bcl.u.removeClass(bcl.c.distributor.comparator.props.$containerComparated, "data-info-1-hide");
    bcl.u.removeClass(bcl.c.distributor.comparator.props.$containerComparated, "data-info-2-hide");
    bcl.u.removeClass(bcl.c.distributor.comparator.props.$containerComparated, "data-info-3-hide");

    if (bcl.u.containsClass(bcl.c.distributor.comparator.props.$containerComparated, bcl.c.distributor.props.classNameHidden)) {
      if (bcl.c.distributor.comparator.props.update) {
        bcl.c.distributor.comparator.props.update = false;

        if (!bcl.c.distributor.comparator.props.map) {
          bcl.c.distributor.map.handleInit(function () {
            bcl.c.distributor.map.init("comparator");
            bcl.c.distributor.comparator.initMap();
            bcl.c.distributor.comparator.render();
            if (bcl.u.utag.isEnabled()) {
              bcl.c.distributor.comparator.pushEvent();
            }
          });
        } else {
          bcl.c.distributor.comparator.render();
          if (bcl.u.utag.isEnabled()) {
            bcl.c.distributor.comparator.pushEvent();
          }
        }

        bcl.s.currency.convertPrices(bcl.c.distributor.comparator.props.$containerComparated);
      }

      bcl.c.distributor.comparator.setPathShare();

      $node = bcl.c.distributor.comparator.props.$containerComparated;
    } else {
      if (bcl.c.socialSharing && bcl.c.socialSharing.props) {
        bcl.c.socialSharing.props.shareUrl = null;
      }
    }

    bcl.u.toggleClass(bcl.c.distributor.props.$container, bcl.c.distributor.props.classNameHidden);
    bcl.u.toggleClass(bcl.c.distributor.comparator.props.$containerComparated, bcl.c.distributor.props.classNameHidden);

    bcl.c.distributor.goToNode($node);
  },

  pushEvent: function () {
    const ids = [],
      product_list = [],
      product_price = [],
      product_position = [];
    let event_label = "";

    for (let i = 0; i < bcl.c.distributor.comparator.props.lastDataComparator.length; i++) {
      const item = bcl.c.distributor.comparator.props.lastDataComparator[i];

      ids.push(item.info.sabre_id);
      product_list.push("hotels comparison results list");
      product_price.push(item.info.hotelLocalLowPriceFormatted);
      product_position.push(item.positionByUtag + "");
      event_label += name.toLowerCase();

      if (i != bcl.c.distributor.comparator.props.lastDataComparator - 1) {
        event_label = "|";
      }
    }

    try {
      const obj = {
        ecommerce_action: "detail_list",
        ecommerce_action_gtag: "hotels comparison results list",
        ecommerce_action_field_list_gtag: "view_item_list",

        product_id: ids,
        product_list: product_list,
        product_position: product_position,
        product_price: product_price,

        event_category: "comparison module",
        event_action: "" + ids.length,
        event_label: event_label,
        "tealium event": "comparison",
      };

      bcl.u.utag.push(obj);
    } catch (e) {
      console.error("Event push error", e);
    }
  },

  render: function () {
    const data = [];
    let isPromocode = false,
      isDiscount = false,
      isValue = false;

    bcl.c.distributor.comparator.deleteMarkers();

    for (let i = 0; i < bcl.c.distributor.props.data.length; i++) {
      const item = bcl.c.distributor.props.data[i],
        key = bcl.c.distributor.comparator.getInData(item, bcl.c.distributor.props.propInData);

      if (bcl.c.distributor.comparator.props.data[key]) {
        item.positionByUtag = i + 1;
        data.push(item);

        if (item.offer) {
          if (item.offer.couponCode) {
            isPromocode = true;
          }

          if (item.offer.type === "discount") {
            isDiscount = true;
          }

          if (item.offer.type === "value") {
            isValue = true;
          }
        }

        bcl.c.distributor.comparator.createMarker(item, key);

        if (bcl.c.distributor.comparator.props.countHotel === data.length) {
          break;
        }
      }
    }

    bcl.c.distributor.comparator.updateMap();

    let method = isPromocode ? "removeClass" : "addClass";
    bcl.u[method](bcl.c.distributor.comparator.props.$containerComparated, "mod--not-promocode");

    method = isDiscount ? "removeClass" : "addClass";
    bcl.u[method](bcl.c.distributor.comparator.props.$containerComparated, "mod--not-discount");

    method = isValue ? "removeClass" : "addClass";
    bcl.u[method](bcl.c.distributor.comparator.props.$containerComparated, "mod--not-value");

    if (data.length !== 3) {
      bcl.u.addClass(bcl.c.distributor.comparator.props.$containerComparated, bcl.c.distributor.comparator.props.classNameNoDeleteItems);
    }

    bcl.c.distributor.comparator.props.lastDataComparator = data;

    let $nodes = bcl.c.distributor.comparator.props.$containerComparated.getElementsByClassName("data-info-1");

    bcl.u.forEach($nodes, function ($element) {
      bcl.c.distributor.comparator.processBlock($element, data);
    });

    /*------------------------------------------------------------------------------------------------------------*/

    $nodes = bcl.c.distributor.comparator.props.$containerComparated.getElementsByClassName("href-hotel-comparator-JS");

    bcl.u.forEach($nodes, function ($element, index) {
      const item = data[index];

      if (!item) {
        return;
      }
      if (bcl.u.containsClass(document.body, "isAuthor")) {
        $element.setAttribute("href", item.urlPath);
      } else {
        $element.setAttribute("href", item.urlPath.replace(".html", ""));
      }
    });

    /*------------------------------------------------------------------------------------------------------------*/

    $nodes = bcl.c.distributor.comparator.props.$containerComparated.getElementsByClassName("booking-button-JS");

    bcl.u.forEach($nodes, function ($element, index) {
      const item = data[index];

      if (!item) {
        return;
      }

      $element.setAttribute("data-hotel", item.info.hotelId);
      $element.setAttribute("data-redirect", "true");
      $element.setAttribute("data-searchtitle", item.info.search_title);
      $element.setAttribute("data-maxagechild", item.info.minimum_age_for_adults);
      $element.setAttribute("data-adultsonly", item.info.is_hotel_adults_only);
    });

    bcl.c.fastbooking.initBookings(bcl.c.distributor.comparator.props.$containerComparated);

    /*------------------------------------------------------------------------------------------------------------*/

    if (data && data[0] && data[0].offer) {
      $nodes = bcl.c.distributor.comparator.props.$containerComparated.getElementsByClassName("grid__hotel-card");

      bcl.u.forEach($nodes, function ($element, index) {
        const item = data[index];

        if (!item) {
          return;
        }

        $element.setAttribute("data-hotel-id", item.info.hotelId);
        $element.setAttribute("data-offer-id", item.offer.id);
        $element.setAttribute("data-offer-value", item.offer.value_rate);
        $element.setAttribute("data-index", index);
        $element.setAttribute("data-offer-type", item.offer.type === "value" ? "absolute" : "percentage");

        const $button = $element.getElementsByClassName("button-JS");
        index = 0;

        if (item.offer.couponCode) {
          bcl.u.addClass($button[1], "hidden");
          bcl.u.removeClass($button[0], "hidden");
          $button[index].setAttribute("data-value", item.offer.couponCode);
        } else {
          bcl.u.addClass($button[0], "hidden");
          bcl.u.removeClass($button[1], "hidden");
          index = 1;
        }

        if (item.offer.actived === 1) {
          bcl.u.removeClass($button[index], "mod--cta-disabled");
        } else {
          bcl.u.addClass($button[index], "mod--cta-disabled");
        }

        bcl.u.setButtonOfferCard($element, item, index);
      });
    }
  },

  processBlock: function ($element, data) {
    const $children = $element.parentNode.children;
    let checkValues = null,
      lastValue = null;

    for (let i = 0; i < 3; i++) {
      if (i < data.length) {
        bcl.u.removeClass($children[i], bcl.c.distributor.props.classNameHidden);
        const retValue = bcl.c.distributor.comparator.processDataInfo($children[i], data[i]);
        if (retValue && !checkValues) {
          if (!lastValue) {
            lastValue = retValue;
          } else if (lastValue !== retValue) {
            checkValues = true;
          }
        }
      } else {
        bcl.u.addClass($children[i], bcl.c.distributor.props.classNameHidden);
      }
    }

    if (!checkValues && lastValue) {
      const parentNode = $element.closest(".grid__row");
      if (lastValue === 1) {
        bcl.u.addClass(parentNode, bcl.c.distributor.props.classNameHidden);
      } else {
        bcl.u.addClass(parentNode, bcl.c.distributor.comparator.props.classNameHiddenByCheck);
      }
    }
  },

  processDataInfo: function ($element, item) {
    if (bcl.u.containsClass($element, "mod--check-price")) {
      return bcl.c.distributor.comparator.processDataInfoSimply($element, item);
    } else {
      bcl.c.distributor.comparator.processDataInfoComplex($element, item);
    }

    if (bcl.u.containsClass($element, "mod--room")) {
      return bcl.c.distributor.comparator.processDataInfoRoom($element, item);
    }
  },

  processDataInfoSimply: function ($element, item) {
    const property = $element.getAttribute(bcl.c.distributor.comparator.props.nameAttrPathInfo);
    const name = $element.dataset.name;

    if (!property || !name) {
      return;
    }

    const valueItem = bcl.u.getInData(item, property),
      $child = $element.children[0];

    bcl.u.removeAllClass($child);

    if (bcl.c.distributor.comparator.checkDataInfoSimply(valueItem, name)) {
      bcl.u.addClass($child, "icon-tick");
      return 2;
    }

    bcl.u.addClass($child, "icon-close");

    return 1;
  },

  checkDataInfoSimply: function (valueItem, name) {
    if (!valueItem) {
      return false;
    }

    if (typeof valueItem === "boolean") {
      return true;
    }

    if (valueItem instanceof Array && valueItem.includes(name)) {
      return true;
    }

    return false;
  },

  processDataInfoComplex: function ($element, item) {
    const $nodes = $element.querySelectorAll("[" + bcl.c.distributor.comparator.props.nameAttrPathInfo + "]");

    bcl.u.forEach($nodes, function ($item) {
      const property = $item.getAttribute(bcl.c.distributor.comparator.props.nameAttrPathInfo);

      if (property) {
        if (property === "image") {
          bcl.c.distributor.comparator.processDataInfoByImage($item, item, property);
        } else {
          bcl.c.distributor.comparator.processDataInfoByAttr($item, item, property);
        }
      }
    });
  },

  processDataInfoByAttr: function ($item, item, property) {
    let value = bcl.u.getInData(item, property);
    const attrValue = $item.getAttribute("data-info-attr");

    if (value || value === 0) {
      bcl.u.removeClass($item, "hidden");
      if (bcl.u.containsClass($item, "c-price__value-JS")) {
        //Fix for paso 0
        const hotelId = item.info.hotelId;
        const mapParent = bcl.c.distributor.map.props.$container.parentNode;
        const cardWitPrice = mapParent.querySelector('[data-hotel-id="' + hotelId + '"]');
        let priceContainer = cardWitPrice.querySelector(".c-price__value-JS:not(.mod--strike)");
        if (priceContainer) {
          const price = priceContainer.textContent;
          const marketPrice = cardWitPrice.querySelector(".c-price__value-JS:not(.mod--strike)").dataset.marketPrice;
          value = parseFloat(price.replace(",", ""));
          $item.dataset.marketPrice = marketPrice;
        } else {
          priceContainer = cardWitPrice.querySelector(".c-price__value-JS");
          if (priceContainer) {
            const price = priceContainer.textContent;
            const marketPrice = cardWitPrice.querySelector(".c-price__value-JS:not(.mod--strike)").dataset.marketPrice;
            value = parseFloat(price.replace(",", ""));
            $item.dataset.marketPrice = marketPrice;
          }
        }

        $item.innerText = value;
      }

      if (attrValue) {
        $item.setAttribute(attrValue, value);

        if (attrValue === "src" && value) {
          bcl.u.removeClass($item, "hidden");
        }
      } else {
        if (!value && value !== 0) {
          const secondProperty = $item.getAttribute(bcl.c.distributor.comparator.props.nameSecondAttrPathInfo);

          value = bcl.u.getInData(item, secondProperty);
        }

        if ($item.dataset.formatdate) {
          const date = new Date(value);

          value = date.toLocaleDateString(bcl.c.fastbooking.calendar.props.location, {});
        }

        $item.innerText = value;
      }

      if (bcl.u.containsClass($item, "score")) {
        bcl.c.distributor.comparator.updateScore($item, value);
      }
    } else if (attrValue === "src" || $item.dataset.hidden === "true") {
      bcl.u.addClass($item, "hidden");
    }
  },

  processDataInfoByImage: function ($item, item) {
    const $node = bcl.c.distributor.comparator.props.data[bcl.c.distributor.comparator.getInData(item, bcl.c.distributor.props.propInData)],
      $containerImage = $node.getElementsByClassName("c-container-image");

    if (!$containerImage || !$containerImage[0]) {
      return;
    }

    const $imageClone = $containerImage[0].cloneNode(true);

    while ($item.children[0]) {
      $item.removeChild($item.firstChild);
    }

    $item.appendChild($imageClone);
  },

  processDataInfoRoom: function ($element, item) {
    const property = $element.getAttribute(bcl.c.distributor.comparator.props.nameAttrPathInfo),
      path = $element.getAttribute(bcl.c.distributor.comparator.props.nameAttrPathRoom),
      room = $element.getAttribute(bcl.c.distributor.comparator.props.nameAttrRoom),
      arrayRooms = bcl.u.getInData(item, property);
    let valueRoom;

    for (let i = 0; i < arrayRooms.length; i++) {
      if (arrayRooms[i][path] === room) {
        valueRoom = arrayRooms[i];
        break;
      }
    }

    if (!valueRoom) {
      bcl.u.addClass($element, bcl.c.distributor.comparator.props.classNameHiddenBlock);
      return;
    } else {
      bcl.u.removeClass($element, bcl.c.distributor.comparator.props.classNameHiddenBlock);
    }

    const $title = $element.closest(".grid__row").children[0],
      propertyParent = $title.getAttribute(bcl.c.distributor.comparator.props.nameAttrPathRoom);
    bcl.c.distributor.comparator.processDataInfoByAttr($title, valueRoom, propertyParent);

    const $nodes = $element.querySelectorAll("[" + bcl.c.distributor.comparator.props.nameAttrPathRoom + "]");

    bcl.u.forEach($nodes, function ($item) {
      const property = $item.getAttribute(bcl.c.distributor.comparator.props.nameAttrPathRoom);

      if (property) {
        bcl.c.distributor.comparator.processDataInfoByAttr($item, valueRoom, property);
      }
    });
  },

  updateScore: function ($item, value) {
    const parent = $item.closest(".c-tripadvisor"),
      $node = parent.getElementsByClassName("ta__dots")[0],
      $children = $node.children;

    for (let i = 0; i < $children.length; i++) {
      const $subNode = $children[i].children[0];

      bcl.u.removeAllClass($subNode);
      bcl.u.updateClassByDotsScore($subNode, value, i);
    }
  },

  beforeInitCards: function ($cards) {
    const length = $cards.length;

    if (length < 2) {
      bcl.c.distributor.comparator.disabledComparator($cards);
      return;
    }

    if (length < 4) {
      bcl.c.distributor.comparator.fullComparator($cards);
      return;
    }

    if (!bcl.cookie.get(bcl.c.distributor.comparator.props.cookieComparatorPopover)) {
      bcl.s.tooltip.activeTooltip("tooltip-full-JS");

      bcl.c.distributor.comparator.props.tooltipActive = true;

      bcl.cookie.set(bcl.c.distributor.comparator.props.cookieComparatorPopover, true, { path: "/" });
    }
  },

  disabledComparator: function () {
    bcl.c.distributor.comparator.props.disabled = true;
    const $buttons = bcl.c.distributor.props.$container.getElementsByClassName("comparator-btn-compared");

    bcl.u.forEach($buttons, function ($button) {
      bcl.u.addClass($button, "mod--disabled");
    });
  },

  fullComparator: function () {
    bcl.c.distributor.comparator.props.fullComparator = true;
  },

  initMap: function () {
    let $container = bcl.c.distributor.comparator.props.$containerComparated.getElementsByClassName(bcl.c.distributor.comparator.props.classNameMap);

    if (!$container || !$container[0]) {
      return;
    }

    $container = $container[0];

    bcl.c.distributor.comparator.props.$cardMap = $container.children[0];
    bcl.c.distributor.comparator.props.$containerMap = $container.children[1];

    bcl.c.distributor.map.createMap(bcl.c.distributor.comparator.props);

    bcl.c.distributor.map.addMarkersClustering(bcl.c.distributor.comparator.props);
  },

  updateMap: function () {
    if (!bcl.c.distributor.comparator.props.$containerMap) {
      return;
    }

    bcl.c.distributor.comparator.props.markerClustering.clearMarkers();

    bcl.c.distributor.comparator.props.markerClustering.addMarkers(bcl.c.distributor.comparator.props.markers, false);

    bcl.c.distributor.map.setFitBounds(bcl.c.distributor.comparator.props);

    bcl.c.distributor.comparator.props.lastCard = null;

    bcl.u.forEach(bcl.c.distributor.comparator.props.markers, function (marker) {
      bcl.c.distributor.map.updateLabelMarker("currency", marker);
    });
  },

  createMarker: function (item, key) {
    if (!bcl.c.distributor.comparator.props.$containerMap || !bcl.c.distributor.map.checkLocation(item)) {
      return;
    }

    bcl.c.distributor.map.createMarker(bcl.c.distributor.comparator.props, bcl.c.distributor.map.generateLabel(item), key, item);

    window.google.maps.event.addListener(bcl.c.distributor.comparator.props.markers[key], "click", function () {
      bcl.c.distributor.comparator.addCardInMap(item, key);
    });
  },

  addCardInMap: function (item, key) {
    if (!bcl.c.distributor.comparator.props.$containerMap) {
      return;
    }

    if (bcl.c.distributor.comparator.props.$cardMap.children.length) {
      bcl.c.distributor.comparator.props.$cardMap.removeChild(bcl.c.distributor.comparator.props.$cardMap.children[0]);
    }

    if (bcl.c.distributor.comparator.props.lastCard && bcl.c.distributor.comparator.props.lastCard === key) {
      bcl.c.distributor.comparator.props.lastCard = null;
      return;
    }

    const $article = bcl.c.distributor.comparator.props.data[key],
      $articleCloned = $article.cloneNode(true);

    bcl.c.distributor.comparator.props.lastCard = key;

    bcl.u.mobile.isMobile() && bcl.u.addClass($articleCloned, "mod--horizontal-card-compact");
    bcl.c.distributor.comparator.props.$cardMap.appendChild($articleCloned);

    const buttonBooking = bcl.c.distributor.comparator.props.$cardMap.getElementsByClassName(bcl.c.fastbooking.commonProps.fastbooking.selectors.buttonRoom);
    delete buttonBooking[0].dataset.load;

    bcl.modal.init(bcl.c.distributor.comparator.props.$cardMap);
    bcl.c.fastbooking.initBookings(bcl.c.distributor.comparator.props.$cardMap);

    bcl.u.removeClass(bcl.c.distributor.comparator.props.$cardMap, "hidden");

    const closeBtn = bcl.c.distributor.comparator.props.$cardMap.querySelector(".c-close-btn");
    closeBtn &&
      closeBtn.addEventListener("click", function () {
        bcl.c.distributor.comparator.addCardInMap(item, key);
      });
  },

  deleteMarkers: function () {
    if (!bcl.c.distributor.comparator.props.$containerMap) {
      return;
    }

    for (const key in bcl.c.distributor.comparator.props.markers) {
      bcl.c.distributor.comparator.props.markers[key].setMap(null);
    }

    bcl.c.distributor.comparator.props.markers = [];

    if (typeof google !== "undefined") {
      bcl.c.distributor.comparator.props.bounds = new window.google.maps.LatLngBounds();
    }

    bcl.u.addClass(bcl.c.distributor.comparator.props.$cardMap, "hidden");
  },

  isPendingOpenComparator: function () {
    const paths = bcl.c.distributor.props.$container.getAttribute("data-comparator");
    if (!paths) {
      bcl.c.distributor.comparator.props.paths = null;

      return;
    }

    bcl.c.distributor.comparator.props.paths = paths.split(",");
  },

  pendingOpenComparator: function (flag) {
    if (bcl.c.distributor.comparator.props.paths === null) {
      return;
    }

    if (bcl.c.distributor.comparator.props.paths === undefined) {
      bcl.c.distributor.comparator.isPendingOpenComparator();
    }

    if (bcl.c.distributor.comparator.props.paths === null) {
      return;
    }

    const paths = bcl.c.distributor.comparator.props.paths,
      $cards = [];

    for (let i = 0; i < paths.length; i++) {
      const $card = bcl.c.distributor.results.props.$container.querySelector("[" + bcl.c.distributor.results.props.nameAttrCard + '="' + paths[i] + '"]');

      if ($card) {
        $cards.push($card);
      }
    }

    if ($cards.length < 2) {
      return;
    }

    for (let n = 0; n < $cards.length; n++) {
      bcl.u.triggerEvent($cards[n].children[0], "click");
    }

    const $buttons = bcl.c.distributor.props.$container.getElementsByClassName("comparator-btn-compared");
    if (!flag) {
      bcl.u.triggerEvent($buttons[0], "click");
    }

    document.addEventListener("comparator-fulldata-loaded", function () {
      if (bcl.c.distributor.props.pendingFullData <= 0) {
        bcl.c.distributor.buttons.clickCompare();
      }
    });
  },

  setPathShare: function () {
    const shareUrl = bcl.c.distributor.props.$container.getAttribute("data-urlShare");
    if (shareUrl) {
      let hotels = "";
      for (const key in bcl.c.distributor.comparator.props.data) {
        hotels += bcl.c.distributor.results.minifyPath(key);
      }

      if (bcl.c.socialSharing && bcl.c.socialSharing.props) {
        bcl.c.socialSharing.props.shareUrl = shareUrl + hotels + "/";
      }
    } else {
      if (bcl.c.socialSharing && bcl.c.socialSharing.props) {
        bcl.c.socialSharing.props.shareUrl = document.location.href;
      }
    }
  },

  getInData: function (item) {
    let output = bcl.u.getInData(item, bcl.c.distributor.props.propInData);

    if (item.offer) {
      output = bcl.u.getInData(item, "path");
      output += bcl.u.getInData(item, "offer.path");
    }

    return output;
  },
};
